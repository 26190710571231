import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment/moment'
// import Select from 'react-select'
import { addArrayCreos, resetCreos } from '../../store/creosReducer'
// import { addArrayFilteredCreos, resetFilteredCreos } from '../../store/filteredCreosReducer'
import { getCreosWithoutMyCreos } from '../../http/creosApi'
import './CreosInfo.css'
import Loader from '../Loader'


const CreosInfo = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const creosStore = useSelector(state => state.creos.creos)
    const filteredCreosStore = useSelector(state => state.filteredCreos.filteredCreos)
    const [loading, setLoading] = useState(true)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })


    useEffect(() => {
        if (creosStore.length === 0) {
            getCreosWithoutMyCreos().then(data => {
                const sortedArr = data.sort((a, b) => (a.creoName > b.creoName) ? 1 : (a.creoName === b.creoName) ? ((a.order > b.order) ? 1 : -1) : -1)

                dispatch(addArrayCreos(sortedArr))
                setLoading(false)
            })
        }
    }, [])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const handleSort = (key) => {
            let direction = 'ascending'
            
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending'
        }
    
        if (filteredCreosStore.length === 0) {
            const sortedData = [...creosStore].sort((a, b) => {
                if (a[key] < b[key]) {
                  return direction === 'ascending' ? -1 : 1
                }
                if (a[key] > b[key]) {
                  return direction === 'ascending' ? 1 : -1
                }
                return 0
              })
          
              dispatch(resetCreos())
              dispatch(addArrayCreos(sortedData))
        } else {
            const sortedData = [...filteredCreosStore].sort((a, b) => {
                if (a[key] < b[key]) {
                  return direction === 'ascending' ? -1 : 1
                }
                if (a[key] > b[key]) {
                  return direction === 'ascending' ? 1 : -1
                }
                return 0
              })
          
              dispatch(resetCreos())
              dispatch(addArrayCreos(sortedData))
        }
        
        setSortConfig({ key, direction })
      }

    return (
        <div className={'form'}>
            {loading ?
                <Loader />
            :
                <>
                    <table className={'сreoProfits'}>
                        <tbody>
                            <tr>
                                <td>Всего креативов</td>
                                <td>{creosStore.length}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className={'tableCreoProfits'}>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('order')}>Template</th>
                                <th onClick={() => handleSort('creoName')}>Creative Name</th>
                                <th>Ordered Geos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creosStore.map(item => (
                                <tr key={item.id}>
                                    <td>{item.template}</td>
                                    <td>{item.creoName.slice(0, 6)}</td>
                                    <td>{item.orderedGeos.join(', ')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            }
        </div>
    )
}

export default CreosInfo