import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useTg} from '../hooks/useTg'
import {addNewCreo} from '../../http/creosApi'
import './Form.css'


const Creo = () => {
    const userStore = useSelector(state => state.user.user)
    const [duration, setDuration] = useState('')
    const [refers, setRefers] = useState([])
    const [creoName, setCreoName] = useState('')
    const [creoVideo, setCreoVideo] = useState('')
    const [hashesStr, setHashesStr] = useState('')
    const [template, setTemplate] = useState('')
    const [formats, setFormats] = useState([])
    
    const {tg} = useTg()

    const onSendData = useCallback(() => {
        const hashes = hashesStr.split(' ')
        if (userStore.role === 'CreoLead' || userStore.role === 'CreoUnit' || userStore.role === 'CreoUnitHelper') {
            if (creoName === '' || template === '' || hashesStr === 0 || refers.length === 0 || duration === '' || formats.length === 0) {
                alert('Заполни все поля')
            } else {
                addNewCreo(creoName, template, hashes, refers, duration, formats, creoVideo).then(data => {
                    data.action = 'addCreo'
                    if (data.message) {
                        alert('Ошибка, такой шаблон уже добавлен')
                    } else {
                        tg.sendData(JSON.stringify(data))
                        alert('Шаблон добавлен')
                    }
                })
                
            }
        } else {
            alert('Нет доступа')
        }
    }, [creoName, template, hashesStr, refers, duration, formats, creoVideo])

    // useEffect(() => {
    //     const getAgesFromDB = async () => {
    //         const data = await getAges().then(ages => {
    //             const modifySources = ages.map(element => {
    //                 return {
    //                     value: element.value,
    //                     label: element.value,
    //                     color: '#000'
    //                 }
    //             })
    //             setAgesOptions(modifySources)
    //         })
    //     }
        
    //     getPlatformsFromDB()
    // }, [])

    const changeCreoName = (e) => {
        setCreoName(e.target.value)
    }

    const changeHashesStr = (e) => {
        setHashesStr(e.target.value)
    }

    const changeFormats = (e) => {
        setFormats(e.target.value)
    }

    const changeRefers = (e) => {
        setRefers(e.target.value)
    }

    const changeTemplate = (e) => {
        setTemplate(e.target.value)
    }

    const changeDuration = (e) => {
        setDuration(e.target.value)
    }

  return (
    <div className={'form'}>
        {/* {userStore.role === 'CreoLead' || userStore.role === 'CreoUnit' ?
            <> */}
                <h3>Введите Шабоон</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Шаблон'}
                    value={template}
                    onChange={changeTemplate}
                />
                <h3>Введите название креатива</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Имя креатива'}
                    value={creoName}
                    onChange={changeCreoName}
                />
                <h3>Файл</h3>
                <input 
                    accept="video/*" 
                    className={'input'}
                    id="icon-button-file"  
                    type="file"
                    onChange={e => setCreoVideo(e.target.files[0])}
                />
                <h3>Введите хештеги к шаблону</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Ввод без "#", каждый хэш через пробел, пример: Зевс Эмоции 3вРяд'}
                    value={hashesStr}
                    onChange={changeHashesStr}
                />
                <h3>Введите форматы к шаблону</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Форматы'}
                    value={formats}
                    onChange={changeFormats}
                />
                <h3>Введите Рефы к шаблону</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Рефы'}
                    value={refers}
                    onChange={changeRefers}
                />
                <h3>Длительность превью</h3>
                <input 
                    className={'input'}
                    type={'text'}
                    placeholder={'Длительность (только цифры)'}
                    value={duration}
                    onChange={changeDuration}
                />
                <button className={'webBtnAdd'} onClick={onSendData}>Добавить</button>
            {/* </>
        :
            <h3>Нет доступа</h3>
        } */}
    </div>
  );
}

export default Creo